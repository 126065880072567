import React from "react";

// Components
// import Button from "../../components/button";

// MUI
import { Container } from "@mui/material";

// MUI Icons
// import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
// import ClearIcon from "@mui/icons-material/Clear";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import TheatersIcon from "@mui/icons-material/Theaters";

// Styles
import "./header.scss";

const Header = () => {
  return (
    <header
      className="header"
      data-testid="header"
    >
      <Container>
        <div className="header__inner">
          <h1 className="header__title">Project Links</h1>
        </div>
      </Container>
    </header>
  );
};

export default Header;
