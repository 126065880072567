import React from "react";

// Layouts
import MUILayout from "../mui";

// Views

// Components
import Header from "../../components/header";
import { Container } from "@mui/material";
// import Seo from "../../components/seo";

// Styles
import "./default.scss";
import SectionHeading from "../../components/section-heading";

const DefaultLayout = () => {
  return (
    <MUILayout>
      <main className="main-wrapper">
        <Header />
        <Container className="inner-container">
          <SectionHeading heading="Introduction" />
          <div className="section__inner">
            <p>
              I've been building this project over the last couple of months to demonstrate my approach to structuring a build, still very much a WIP.
              It showcases how I lay out a project for scalability and maintainability, ensuring a clean and organized codebase. This was also a great
              opportunity to integrate the{" "}
              <a
                href="https://platform.openai.com/docs/api-reference/introduction"
                className="footer__list-link"
              >
                OpenAI API
              </a>
              , showcasing the power of AI technologies now available.
            </p>
            <p>
              The deployment process is streamlined, with a clear path from development to production. To automate delivery, I've integrated a CI/CD
              pipeline that triggers on merges to the main branch, ensuring seamless updates with minimal manual intervention. With accessibility in
              mind, I've ensured that the application is usable by as many people as possible, following best practices to create an inclusive
              experience.
            </p>
          </div>
          <SectionHeading
            heading="My Movie Database"
            buttonLink="https://my-mdb.co.uk/"
            glow
          />
          <div className="section__inner">
            <p>
              The My Movie Database website is a dynamic web application built using React, designed to provide users with an intuitive and efficient
              way to search for movies and TV series. It leverages
              <a
                href="https://developer.themoviedb.org/docs/getting-started"
                className="footer__list-link"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                The Movie Database{" "}
              </a>{" "}
              API to fetch real-time movie data, including titles, posters, descriptions, and other relevant details.
            </p>
          </div>

          <SectionHeading
            heading="Github Repository"
            buttonLink="https://github.com/SteveDenning/react-movie-search"
            glow
          />
          <div className="section__inner">
            <p>The repository is publicly accessible, but all API keys are securely stored elsewhere to ensure proper protection.</p>
          </div>
        </Container>
      </main>
    </MUILayout>
  );
};

export default DefaultLayout;
