// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCvhzcrRvh-Ps98XRtTKhyL8pWaB7b-dqc",
  authDomain: "portfolio-f4dd5.firebaseapp.com",
  projectId: "portfolio-f4dd5",
  storageBucket: "portfolio-f4dd5.firebasestorage.app",
  messagingSenderId: "304692223536",
  appId: "1:304692223536:web:f891cbb3f1d7f48f4497f5",
  measurementId: "G-4CHV1MZJG4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
if (process.env.NODE_ENV !== "development") {
  getAnalytics(app);
}
