import React from "react";

// Components

// MUI Icons

// Styles
import "./section-heading.scss";

interface Props {
  heading: string;
  buttonLink?: string;
  glow?: boolean;
}

const SectionHeading: React.FC<Props> = ({ heading, buttonLink, glow }) => {
  return (
    <div
      className="section-heading"
      data-testid="section-heading"
    >
      <h2>
        {buttonLink ? (
          <a
            href={buttonLink}
            className={`section-heading__header${glow ? " text-glow" : ""}`}
          >
            {heading}
          </a>
        ) : (
          heading
        )}
      </h2>
    </div>
  );
};

export default SectionHeading;
